import React, {useEffect} from "react";
import "./style.css";

export const Work = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="work">
      <div className="div">
        <img className="intro" alt="Intro" src="/img/intro.png" />
        <div className="overlap">
          <div className="the-challenge">
            <div className="group">
              <div className="group-2">
                <div className="text-wrapper">The Challenge</div>
                <p className="p">
                  KickBit® offers a curated selection of sneakers and casual shoes for athletes and sneakerheads that
                  suits their lifestyle and sport. We were assigned to create and design an e-commerce app, that brings
                  the user a simple, smooth experience in getting their hands on all the freshest and latest drops with
                  ease.
                </p>
              </div>
            </div>
          </div>
          <div className="system">
            <div className="design-systeme-wrapper">
              <div className="design-systeme">
                <img className="mask-group" alt="Mask group" src="/img/mask-group-4.png" />
              </div>
            </div>
          </div>
          <img className="screens" alt="Screens" src="/img/screens.png" />
          <div className="group-wrapper">
            <div className="group-3">
              <div className="text-wrapper-2">Strategy-Research-Design</div>
              <p className="the-product-design">
                The product design strategy begins with designing an MVP with which the stakeholders gather relevant
                information to improve and iterate the product throughout the design lifecycle. The definition and
                conceptualization of the product MVP start with the research stage, including workshops, zoom calls,
                feedbacks, an in-depth analysis of the sector and its context, analyses of users and their needs through
                interviews and validations and tests with users and influencers in the industry.
                <br />
                <br />
                The derived insights carve out the roadmap for the product design, the definition and refinement of the
                features and the usability, and the definition and development of the product branding.
              </p>
            </div>
          </div>
        </div>
        <div className="homepage">
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="text-wrapper-3">Seamless Shopping</div>
              <p className="text-wrapper-4">
                The minimal approach ensures accessibility and a more comfortable shopping experience without puzzling
                the user, making it easier to navigate and provide an overall seamless shopping experience. The
                categories section ensures telling the user subtly about the products, and categorization makes it super
                more comfortable navigating, filtering, and buying products the user wants with ease.
              </p>
            </div>
          </div>
        </div>
        <div className="product-page">
          <div className="div-wrapper">
            <div className="overlap-group-2">
              <div className="text-wrapper-5">Easy Access</div>
              <p className="text-wrapper-6">
                The product page was designed keeping the user in mind for easy access to all the details, from images
                to more information to pricing.
              </p>
            </div>
          </div>
        </div>
        <div className="checkout">
          <div className="group-4">
            <div className="overlap-group-3">
              <div className="text-wrapper-7">Quick Checkout</div>
              <p className="text-wrapper-8">
                With everyday attention span goes down by 0.0001% —combining unique products, detailed products and
                faster checkout and most of all, thoughtful experiences = Happy users.
              </p>
              <div className="frame" />
            </div>
          </div>
        </div>
        <img className="bottomimage" alt="Intro" src="/img/image.png" />
      </div>
    </div>
  );
};
